import React, { useEffect, useState } from "react";
import { Tab, Nav, Accordion, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImPlay3 } from "react-icons/im";
import { ImCheckmark } from "react-icons/im";
import { GoLock } from "react-icons/go";

import swal from "sweetalert";
import { API_ROOT_URL } from "../../../constants";

const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
};

const SIDE_LISTS = {
  ID: 101,
};
const accordionBlog = [{ id: 101, title: "Course Slides" }];

function InductionSlidesList({ setCurrentSlideContent, ...props }) {
  const totalSlides = props.slides.length - 1;
  const inductionID = props.inductionID;
  const role = useSelector((state) => state.auth.auth.role);
  const token = useSelector((state) => state.auth.auth.token);

  //stepper
  const steps = [];
  const [active, setActive] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [enabledStep, setEnabledStep] = useState(1); // enable next induction click
  const [complete, setComplete] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [timer, setTimer] = useState("");
  const { id } = useParams();
  const [scrollOffset, setScrollOffset] = useState(0);
  const [pageUp, pageDown, home, end] = [33, 34, 36, 35];

  const [visitedSlides, setVisitedSlides] = useState([0]); // Marking the first slide as visited initially

  const onClick = async (slideData, i) => {
    const handleClick = async () => {
      // Check if the user is not a USER role
      if (USER_ROLES.USER !== role) {
        const id = slideData._id;
        const response = await getSlideData(id, token);

        if ("status" in response && response.status === true) {
          // Mark the slide as visited
          setVisitedSlides((prevVisitedSlides) => [...prevVisitedSlides, i]);
          setCurrentStep(i);
          setCurrentSlideContent(response.data);
        }
      } else {
        if (i <= enabledStep) {
          // If the slide is already visited
          const id = slideData._id;
          const response = await getSlideData(id, token);

          if (
            !visitedSlides.includes(i) &&
            "status" in response &&
            response.status === true
          ) {
            // Mark slide as visited
            setVisitedSlides((prevVisitedSlides) => [...prevVisitedSlides, i]);
            setCurrentStep(i);
            setEnabledStep(i + 1);
            setCurrentSlideContent(response.data);
          } else {
            // Use the callback function to ensure you work with the updated state
            setVisitedSlides((prevVisitedSlides) => [...prevVisitedSlides, i]);
            setCurrentStep(i);
            setEnabledStep(i + 1);
            setCurrentSlideContent(response.data);
          }
        } else {
          // Handle USER trying to jump to slides not allowed
          if (!visitedSlides.includes(i - 1)) {
            swal("Error", "You are trying to skip the induction!", "error");
          } else {
            // Make the API call for the next visited slide
            const id = slideData._id;
            const response = await getSlideData(id, token);

            if ("status" in response && response.status === true) {
              // Mark the next slide as visited
              setVisitedSlides((prevVisitedSlides) => [
                ...prevVisitedSlides,
                i - 1 + 1,
              ]);
              setCurrentStep(i);
              setCurrentSlideContent(response.data);
            } else {
              // Handle the case when the API call for the next slide fails
              console.error("Error fetching next slide data");

              // You may want to set a placeholder content or handle the error in a different way
              setCurrentSlideContent({
                error: "Failed to load slide content",
              });
            }
          }
        }
      }
    };

    // Use state updater function's callback to ensure state is updated before calculating percentage
    handleClick();
  };
  //     const handleClick = (slideData, i) => {
  //       if (USER_ROLES.USER !== role) {
  //         setCurrentSlideContent(slideData);
  //         setEnabledStep(i + 1);
  //         setCurrentStep(i);
  //       } else if (i <= enabledStep) {
  //         setActive(i);
  //         setCurrentStep(i);
  //         setEnabledStep(i + 1);
  //         setCurrentSlideContent(slideData);
  //       } else if (USER_ROLES.USER === role) {
  //         return swal("Error", "You are trying to skip Induction!", "error");
  //       }
  //     };

  const getSlideData = async (id, token) => {
    return await fetch(`${API_ROOT_URL}/slides/getbyslideid/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
  };
  // on keyboard button press
  const keyPress = async (e, i) => {
    // next key press
    if (e.keyCode === 39) {
      if (currentStep < totalSlides) {
        const id = props.slides[currentStep + 1]._id;
        setActive(currentStep + 1);
        setCurrentStep(currentStep + 1);
        setEnabledStep(currentStep + 1);
        const response = await getSlideData(id, token);
        if ("status" in response && response.status === true) {
          // Update setVisitedSlides to include the next step
          setVisitedSlides((prevVisitedSlides) => [
            ...prevVisitedSlides,
            currentStep + 1,
          ]);
          setCurrentSlideContent(response.data);
        }
      } else if (USER_ROLES.USER === role) {
        swal("Success", "Induction Completed! Please try quiz!", "success");
      }
    }

    // on previous key press
    // on previous key press
    if (e.keyCode === 37) {
      if (currentStep > 0) {
        const id = props.slides[currentStep - 1]._id;
        setActive(currentStep - 1);
        setCurrentStep(currentStep - 1);
        setEnabledStep(currentStep);
        const response = await getSlideData(id, token);
        if ("status" in response && response.status === true) {
          // Update setVisitedSlides to include the current step
          setVisitedSlides((prevVisitedSlides) => [
            ...prevVisitedSlides,
            currentStep,
          ]);
          setCurrentSlideContent(response.data);
        }
      } else if (USER_ROLES.USER === role) {
        // Check if the current step is already the first step
        if (currentStep === 0) {
          // Show a message or handle the situation as needed
          return swal("Info", "You are already on the first slide.", "info");
        }

        // Reset visited slides and progress percentage
        setActive(0);
        setCurrentStep(0);
        setEnabledStep(active + 1);
        setVisitedSlides([0]);

        const id = props.slides[0]._id;
        const response = await getSlideData(id, token);
        if ("status" in response && response.status === true) {
          setCurrentSlideContent(response.data);
        }

        return swal("Error", "This is the First slide of Induction!", "error");
      }
    }
  };

  let percentageforProgressbar;
  const uniqueVisitedSlides = [...new Set(visitedSlides)]; // Remove duplicates

  if (totalSlides === 0) {
    percentageforProgressbar = 100 + "%";
  } else {
    percentageforProgressbar =
      Math.round((uniqueVisitedSlides.length / (totalSlides + 1)) * 100) + "%";
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [active, currentStep, enabledStep]);

  // get Questions
  const getQuestions = async () => {
    const response = await fetch(`${API_ROOT_URL}/mcq/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((data) => data.json());
    if ("status" in response && response.status == true) {
      setQuestions(response.data);
      setTimer(response.quizTimer);
    } else {
      //setHasError(true);
    }
  };

  const handleStartTest = () => {
    getQuestions();
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  return (
    <div>
      {USER_ROLES.USER === role ? (
        <div className="card h-auto">
          <div className="card-header border-0 pb-0">
            <h4>Progress</h4>
            <span className="font-w600">{percentageforProgressbar}</span>
          </div>

          <div className="card-body pt-0">
            <div className="progress-box">
              <div className="progress ">
                <div
                  className="progress-bar bg-success"
                  style={{
                    width: percentageforProgressbar,
                    height: "12px",
                    borderRadius: "4px",
                  }}
                  role="progressbar"
                ></div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                {/* <h1> title </h1> */}
                <span className="font-w600">
                  {currentStep + 1}/{totalSlides + 1}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="custome-accordion">
        <Accordion className="accordion" defaultActiveKey="0">
          {accordionBlog.map((data, i) => (
            <Accordion.Item className="card" key={i} eventKey={`${i}`}>
              <Accordion.Header as="h2" className="accordion-header border-0">
                <span className="acc-heading">{data.title}</span>
                {data.id === 1 ? (
                  <span className="">
                    ({active + 1}/{props.slides.length})
                  </span>
                ) : null}
              </Accordion.Header>
              {data.id === SIDE_LISTS.ID ? (
                <Accordion.Collapse eventKey={`${i}`} id="collapseOne">
                  <div
                    className="accordion-body card-body pt-0 scrollable"
                    style={{ maxHeight: "535px", behavior: "smooth" }}
                  >
                    {props.slides.map((slide, j) => {
                      return (
                        <div
                          key={slide._id}
                          className={`acc-courses ${
                            currentStep === j ? "active" : ""
                          }`}
                          id={j}
                          onClick={() => onClick(slide, j)}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-between align-items-center">
                                {(USER_ROLES.USER === role && (
                                  <span className="acc-icon">
                                    {visitedSlides.includes(j) ? (
                                      <ImCheckmark
                                        style={{
                                          color: "#FF6A59",
                                          fontSize: "22px",
                                        }}
                                      />
                                    ) : (
                                      <GoLock
                                        style={{
                                          color: "#FF6A59",
                                          fontSize: "22px",
                                        }}
                                      />
                                    )}
                                  </span>
                                )) ||
                                  (USER_ROLES.USER !== role && (
                                    <span className="acc-icon">
                                      {j < currentStep || complete ? (
                                        <ImCheckmark
                                          style={{
                                            color: "#FF6A59",
                                            fontSize: "22px",
                                          }}
                                        />
                                      ) : currentStep === j ? (
                                        <ImPlay3
                                          style={{
                                            color: "#FF6A59",
                                            fontSize: "22px",
                                          }}
                                        />
                                      ) : (
                                        <ImCheckmark
                                          style={{
                                            color: "#FF6A59",
                                            fontSize: "22px",
                                          }}
                                        />
                                      )}
                                    </span>
                                  ))}
                                <h4 className="m-0">{slide.slideTitle.charAt(0).toUpperCase() + slide.slideTitle.slice(1)}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div>
                      <div>
                        {steps?.map((step, i) => (
                          <div
                            key={i}
                            className={`step-item ${
                              currentStep === i + 1 && "active"
                            } ${(i < currentStep || complete) && "complete"} `}
                          >
                            <div className="step">
                              {i < currentStep || complete ? (
                                <GoLock size={40} />
                              ) : (
                                i + 1
                              )}
                            </div>
                            <p className="text-black-300">{step}</p>
                          </div>
                        ))}
                      </div>
                      {!complete && (
                        <span
                          onClick={() => {
                            currentStep === props.slides.length
                              ? setComplete(true)
                              : setCurrentStep((prev) => prev + 1);
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                </Accordion.Collapse>
              ) : null}
            </Accordion.Item>
          ))}

          {USER_ROLES.USER === role ? (
            <div>
              <div className="accordion accordion">
                <div className="card accordion-item">
                  {percentageforProgressbar === "100%" ? (
                    <>
                      {questions.length === 0 ? (
                        <>
                          <Link
                            className="btn btn-info"
                            to={"/inductions"}
                            onClick={exitFullscreen}
                          >
                            Back to Inductions
                          </Link>
                        </>
                      ) : (
                        <>
                          {percentageforProgressbar === "100%" ? (
                            <Link
                              className="btn btn-success"
                              onClick={handleStartTest}
                              to={`/start-test/${inductionID}`}
                            >
                              Start Test
                            </Link>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </Accordion>
      </div>
    </div>
  );
}

export default InductionSlidesList;
