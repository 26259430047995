class AbortControllerService {
    constructor() {
      this.controller = null;
    }
  
    create() {
      this.controller = new AbortController();
      return this.controller;
    }
  
    abort() {
      if (this.controller) {
        this.controller.abort();
      }
    }
  }
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default new AbortControllerService();
