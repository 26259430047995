import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { QuizProvider } from "./jsx/components/Induction/QuizContext";
import { ProfilePictureProvider } from "./store/context/profilePictureProvider";
import { ToastContainer } from "react-toastify";
//import { FakePayloadProvider } from "./jsx/components/Induction/FormContext";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SimpleReactLightbox>
                <BrowserRouter>
                    <ThemeContext>
                        <QuizProvider>
                            <ProfilePictureProvider>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="dark"
                                />
                                <App />
                            </ProfilePictureProvider>
                        </QuizProvider>
                    </ThemeContext>
                </BrowserRouter>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
