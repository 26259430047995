import { AWS_BUCKET_NAME } from "../jsx/constants";
import s3 from "./aws.config";

const getS3DownloadLink = (fileName) => {
    return s3.getSignedUrl("getObject", {
        Bucket: AWS_BUCKET_NAME,
        Key: `uploads/${fileName}`,
        Expires: 60,
        ResponseContentDisposition: `attachment; filename="${fileName}.pdf"`,
    });
};

export default getS3DownloadLink;