export const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  DEPARTMENT: "department",
  COMPANY: "company",
  INSTRUCTOR: "instructor",
  USER: "user",
  END_USER: "end_user"
};

export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL;
export const PROFILE_ASSETS_URL = process.env.REACT_APP_PROFILE_ASSETS_URL;
export const QUERY_ASSETS_URL = process.env.REACT_APP_QUERY_ASSETS_URL;
export const INDUCTION_ASSETS_URL = process.env.REACT_APP_INDUCTION_ASSETS_URL;
export const QUESTIONS_ASSETS_URL = process.env.REACT_APP_QUESTIONS_ASSETS_URL;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME;
export const REACT_APP_RUNNING_MODE = process.env.REACT_APP_RUNNING_MODE
export const DASHBOARD = {};
